<template>
  <div class="group">
    <nav class="group-nav">
      <span>公告管理</span>
      <ul class="group-ul">
        <li
          v-for="(item, index) in headNav"
          :key="index"
          @click="navKey = item.key"
          :class="{ active: item.key === navKey }"
        >
          <span :class="item.cla"></span><span class="nav-title">{{ item.title }}</span>
        </li>
      </ul>
    </nav>
    <header class="announcement-header">
      <div class="add">
        <div class="sum">已发布{{ isP }}/未发布{{ noP }}</div>
        <div class="add-announcement" @click="(showAddGroup = true), (groupId = 'add')">
          <img src="../../../assets/image/icon-progress/icon_plus.png" alt="新建" /><span>新建</span>
        </div>
      </div>
      <div class="graph-progress">
        <span class="status1" :style="{ width: isPre }"></span>
      </div>
    </header>
    <main class="group-main announcement" v-show="navKey === 'announcement'">
      <div class="announcement">
        <ul class="announcement-ul">
          <li v-for="(item, index) in announces" :key="index" @click="(showAddGroup = true), (groupId = item.id)">
            <div class="release">
              <span class="is-release" v-show="item.is_public === '1'">已发布</span>
              <span class="is-release" v-show="item.is_public === '0'">未发布</span>
              <br />
              <span class="pre">30/0</span>
            </div>
            <div class="announce">
              <span class="title">{{ item.title }}</span
              ><br />
              <span class="content" v-html="item.des"></span>
            </div>
          </li>
        </ul>
      </div>
    </main>
    <main class="group-main inbox" v-show="navKey === 'inbox'">
      <div class="announcement">
        <ul class="announcement-ul">
          <li v-for="(item, index) in inbox" :key="index" @click="(preview = true), (inboxId = item.id)">
            <div class="release">
              <span class="un-read" v-show="index < unread">未读</span>
              <span class="is-read" v-show="index >= unread">已读</span>
              <br />
            </div>
            <div class="announce">
              <span class="title">{{ item.title }}</span
              ><br />
              <span class="content" v-html="item.des"></span>
            </div>
          </li>
        </ul>
      </div>
    </main>
    <add-group v-if="showAddGroup" @groupClose="groupClose" :id="groupId" @changeId="changeId" />
    <preview :id="inboxId" type="inbox" v-if="preview" @close="preClose" />
  </div>
</template>

<script>
import addGroup from '@/components/chat/group/addGroup';
import preview from '@/components/chat/group/preview';
import { getAnnounceLis, postAnnounceInboxLis } from '@/service/help';
import cookie from '@/utils/cookie';
export default {
  name: 'Group',
  data() {
    return {
      headNav: [
        {
          key: 'announcement',
          title: '公告管理',
          ico: require('@/assets/image/icon-communicate/icon_gggl_gr.png'),
          cla: 'gggl',
        },
        {
          key: 'inbox',
          title: '收件箱',
          ico: require('@/assets/image/icon-communicate/icon_gggl_gr.png'),
          cla: 'sxj',
        },
      ],
      navKey: 'announcement',
      announces: [
        {
          avatar: '',
          ctime: '',
          des: '',
          id: '',
          is_public: '',
          name: '',
          personnel: '',
          title: '',
          type: '',
          uid: '',
        },
      ],
      inbox: [
        {
          avatar: '',
          ctime: '',
          des: '',
          id: '',
          is_public: '',
          name: '',
          personnel: '',
          title: '',
          type: '',
          uid: '',
        },
      ],
      showAddGroup: false,
      preview: false,
      groupId: 'add', //打开编辑或者添加
      inboxId: '',
      isP: 0,
      noP: 0,
      isPre: '',
      noPre: '',
      read: 0,
      unread: 0,
    };
  },
  components: {
    addGroup,
    preview,
  },
  created() {
    this.getAnnounceLis();
  },
  methods: {
    getAnnounceLis() {
      getAnnounceLis().then(res => {
        console.log(res.data.data);
        this.announces = res.data.data.announceLis;
        this.progressPresent();
      });
      postAnnounceInboxLis({
        token: cookie(document).get('token'),
      }).then(res => {
        console.log(res.data.data);
        this.read = parseInt(res.data.data.is_read_sum);
        this.unread = parseInt(res.data.data.un_read_sum);
        this.inbox = res.data.data.un_read.concat(res.data.data.is_read);
      });
    },
    groupClose() {
      this.getAnnounceLis();
      this.showAddGroup = false;
    },
    changeId(e) {
      this.groupId = e;
      this.getAnnounceLis();
    },
    progressPresent() {
      this.isP = 0;
      this.noP = 0;
      this.announces.forEach(item => {
        if (item.is_public === '1') {
          this.isP = this.isP + 1;
        } else {
          this.noP = this.noP + 1;
        }
      });
      let len = this.announces.length;
      this.isPre = (this.isP / len) * 100 + '%';
      this.noPre = (this.noP / len) * 100 + '%';
    },
    preClose() {
      //关闭预览
      this.preview = false;
    },
  },
};
</script>

<style scoped lang="less">
@import '~@/assets/style/chat/group';
</style>
