<template>
  <div class="pre-cover" @click.stop="$emit('close')" :class="{ 'in-cover': type === 'inbox' }">
    <div class="preview" @click="$event.stopPropagation()" v-if="type === 'edit'">
      <header class="pre-header">
        <button class="recall" v-show="detail.is_public === '1'" @click="recall">撤回</button>
        <div class="close-ico" @click.stop="$emit('close')" />
      </header>
      <main class="pre-main">
        <div class="type">
          <img
            src="../../../assets/image/template_slices/pic_qtz@2x.png"
            width="430px"
            height="200px"
            v-if="detail.type === '群通知'"
          />
          <img
            src="../../../assets/image/template_slices/pic_hy@2x.png"
            width="430px"
            height="200px"
            v-else-if="detail.type === '会议通知'"
          />
          <img
            src="../../../assets/image/template_slices/pic_fj@2x.png"
            width="430px"
            height="200px"
            v-else-if="detail.type === '放假通知'"
          />
          <img
            src="../../../assets/image/template_slices/bg_jz@2x.png"
            width="916px"
            height="662px"
            v-else-if="detail.type === '奖状'"
          />
          <img
            src="../../../assets/image/template_slices/pic_jj@2x.png"
            width="430px"
            height="200px"
            v-else-if="detail.type === '紧急通知'"
          />
          <img
            src="../../../assets/image/template_slices/pic_gz@2x.png"
            width="430px"
            height="200px"
            v-else-if="detail.type === '工作通知'"
          />
          <span v-show="detail.type !== '奖状'">
            {{ detail.type }}
          </span>
          <div class="award-cover" v-show="detail.type === '奖状'">
            <div class="part1">
              <div class="name">
                {{ detail.name }}
              </div>
              <div class="congratulate">
                恭喜你被评为
              </div>
            </div>
            <div class="part2">{{ detail.award }}</div>
            <div class="part3">
              <div class="s1">特发此奖，以资奖励！</div>
              <div class="s2">{{ name }}</div>
              <div class="s3">{{ date }}</div>
            </div>
          </div>
        </div>
        <div class="content" v-show="detail.type !== '奖状'">
          <div class="title">
            {{ detail.title }}
          </div>
          <div v-html="des" class="ck ck-content ck-rounded-corners ck-editor__editable_inline ck-blurred des" />
        </div>
        <div class="comment"></div>
      </main>
    </div>
    <div class="preview" @click="$event.stopPropagation()" v-if="type === 'inbox'">
      <header class="pre-header">
        <div class="close-ico" @click.stop="$emit('close')" />
      </header>
      <main class="pre-main">
        <div class="type">
          <img
            src="../../../assets/image/template_slices/pic_qtz@2x.png"
            width="430px"
            height="200px"
            v-if="inbox.type === '群通知'"
          />
          <img
            src="../../../assets/image/template_slices/pic_hy@2x.png"
            width="430px"
            height="200px"
            v-else-if="inbox.type === '会议通知'"
          />
          <img
            src="../../../assets/image/template_slices/pic_fj@2x.png"
            width="430px"
            height="200px"
            v-else-if="inbox.type === '放假通知'"
          />
          <img
            src="../../../assets/image/template_slices/bg_jz@2x.png"
            width="916px"
            height="662px"
            v-else-if="inbox.type === '奖状'"
          />
          <img
            src="../../../assets/image/template_slices/pic_jj@2x.png"
            width="430px"
            height="200px"
            v-else-if="inbox.type === '紧急通知'"
          />
          <img
            src="../../../assets/image/template_slices/pic_gz@2x.png"
            width="430px"
            height="200px"
            v-else-if="inbox.type === '工作通知'"
          />
          <span v-show="inbox.type !== '奖状'">
            {{ inbox.type }}
          </span>
          <div class="award-cover" v-show="inbox.type === '奖状'">
            <div class="part1">
              <div class="name">
                {{ inbox.title }}
              </div>
              <div class="congratulate">
                恭喜你被评为
              </div>
            </div>
            <div class="part2">{{ inbox.des }}</div>
            <div class="part3">
              <div class="s1">特发此奖，以资奖励！</div>
              <div class="s2">{{ name }}</div>
              <div class="s3">{{ date }}</div>
            </div>
          </div>
        </div>
        <div class="content" v-show="inbox.type !== '奖状'">
          <div class="title">
            {{ inbox.title }}
          </div>
          <div v-html="inbox.des" class="ck ck-content ck-rounded-corners ck-editor__editable_inline ck-blurred des" />
        </div>
        <div class="comment"></div>
      </main>
    </div>
  </div>
</template>

<script>
import { Message } from 'element-ui';
import cookie from '@/utils/cookie';
import { postAnnounceBack, postAnnounceInfo } from '@/service/help';
export default {
  name: 'preview',
  data() {
    return {
      name: '',
      inbox: [
        {
          avatar: '',
          ctime: '',
          des: '',
          id: '',
          is_public: '',
          personnel: '',
          title: '',
          type: '',
          uid: '',
        },
      ],
    };
  },
  created() {
    this.name = cookie.get('name');
    if (this.type === 'inbox') {
      postAnnounceInfo({ id: this.id }).then(res => {
        this.inbox = res.data.data.announce;
      });
    }
  },
  computed: {
    date() {
      return this.parseDate(new Date());
    },
  },
  props: ['detail', 'des', 'id', 'type'],
  methods: {
    parseDate(date) {
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      let d = date.getDate();
      m = m < 10 ? '0' + m : m;
      d = d < 10 ? '0' + d : d;
      return y + '年' + m + '月' + d + '日';
    },
    recall() {
      postAnnounceBack({
        id: this.id,
      }).then(() => {
        this.$emit('recall');
        Message({
          type: 'success',
          message: '撤回成功',
        });
      });
    },
  },
  watch: {},
};
</script>

<style scoped lang="less">
@import './preview.less';
</style>
<!--
<style scoped lang="less">
  @import "preview";
</style>
-->
