<template>
  <div class="cover" @click="close">
    <div class="add-group" @click.stop="member = false">
      <header>
        <div class="title" v-show="id !== 'add'">编辑通知</div>
        <div class="title" v-show="id === 'add'">新增通知</div>
        <div class="close-ico" @click="$emit('groupClose')" />
      </header>
      <main>
        <div class="select-template">
          <span>选择模板</span>
          <div class="select" @click.stop="showTemplate">
            <input type="text" readonly="readonly" ref="st" v-model="form.type" />
            <img src="../../../assets/image/icon-progress/icon_down@2x.png" width="13px" />
          </div>
        </div>
        <div class="select-template member">
          <span>选择人员</span>
          <div class="select" @click.stop="showGroup">
            <input type="text" v-model="form.personnel" readonly="readonly" ref="sm" />
            <img src="../../../assets/image/icon-progress/icon_down@2x.png" width="13px" />
          </div>
          <ul class="option" v-show="member">
            <li v-for="item in members" :key="item.groupid" @click="selectGroup(item.groupname, item.groupid)">
              {{ item.groupname }}
            </li>
          </ul>
        </div>
        <div class="title" v-show="form.type !== '奖状'">
          <span>标题</span><input placeholder="编辑通知" :disabled="form.is_public === '1'" v-model="form.title" />
        </div>
        <div class="content" v-show="form.type !== '奖状'">
          <span class="content-span"> 内容 </span>
          <div class="editor">
            <editor v-show="form.is_public === '0'" :editorData="des" ref="editor" @setData="setDes" />
            <div
              v-show="form.is_public === '1'"
              v-html="des"
              class="ck ck-content ck-rounded-corners ck-editor__editable_inline ck-blurred des"
            />
          </div>
        </div>
        <div class="title name" v-show="form.type === '奖状'">
          <span>领奖人姓名</span><input placeholder="编辑姓名" v-model="form.name" />
        </div>
        <div class="title award" v-show="form.type === '奖状'">
          <span>获奖称号</span><input placeholder="编辑称号" v-model="form.award" />
        </div>
        <div class="button">
          <button class="pre" @click="pre">预览</button>
          <button class="save" @click="save" v-show="form.is_public === '0'">仅保存</button>
          <button class="save-and-pub" v-show="form.is_public === '0'" @click="saveAndPub">保存并发布</button>
          <button class="del" v-show="id !== 'add' && form.is_public === '0'" @click="del">删除</button>
          <button class="del" v-show="form.is_public === '1'" @click="recall">撤回</button>
        </div>
      </main>
    </div>
    <preview
      :des="$refs.editor.editorData"
      :detail="form"
      :id="id"
      type="edit"
      v-if="preview"
      @close="preClose"
      @recall="form.is_public = '0'"
    />
    <groupTemplate v-show="templateShow" @choices="choices($event)" @close="templateShow = false" />
  </div>
</template>

<script>
import { Message } from 'element-ui';
import editor from '@/components/ckeditor/Editor';

import groupTemplate from '@/components/chat/group/groupTemplate';
import {
  postAnnounceDel,
  postAnnounceSimpleInfo,
  postAnnounceMod,
  postAnnounceNew,
  // postCommunicateChatGroupJoinLis,
  postAnnounceBack,
} from '@/service/help';
import preview from './preview.vue';
import cookie from '@/utils/cookie';
export default {
  name: 'addGroup',
  data() {
    return {
      form: {
        ctime: '',
        title: '',
        type: '群通知',
        award: '',
        name: '',
        personnel: '',
        is_public: '0',
        groupid: '',
        des: '',
      },
      templateShow: false,
      member: false,
      members: [],
      preview: false,
      des: '',
    };
  },
  components: {
    editor,
    preview,
    groupTemplate,
  },
  props: {
    id: {
      type: String,
      default: 'add',
    },
  },
  created() {
    if (this.id !== 'add') {
      this.getData();
    }

    // postCommunicateChatGroupJoinLis({
    //   username: cookie(document).get('account'),
    //   token: cookie(document).get('token'),
    // }).then(res => {
    //   this.members = res.data.data;
    // });
  },
  methods: {
    getData() {
      postAnnounceSimpleInfo({ id: this.id }).then(res => {
        let d = res.data.data;
        console.log(d);
        this.form.personnel = d.personnel;
        this.form.groupid = d.group_id;
        this.form.is_public = d.is_public;
        this.form.type = d.type;
        if (d.type === '奖状') {
          this.form.award = d.des;
          this.form.name = d.title;
        } else {
          this.des = d.des;
          this.form.title = d.title;
        }
      });
    },
    setDes(val) {
      this.des = val;
    },
    close() {
      if (this.form.is_public !== '1' && this.des !== '') {
        let esc = confirm('是否退出编辑该通知？');
        if (esc === true) {
          this.$emit('groupClose');
        } else return false;
      } else {
        //已发布的情况
        this.$emit('groupClose');
      }
    },
    pre() {
      //预览
      this.preview = true;
    },
    preClose() {
      this.preview = false;
    },
    showTemplate() {
      if (this.form.is_public !== '1') {
        this.templateShow = !this.templateShow;
      }
    },
    choices(e) {
      this.templateShow = false;
      this.form.type = e;
    },
    showGroup() {
      if (this.form.is_public !== '1') {
        this.member = !this.member;
      }
    },
    selectGroup(name, id) {
      this.form.personnel = name;
      this.form.groupid = id;
      this.member = false;
    },
    save() {
      //仅保存
      let des = this.form.type === '奖状' ? this.form.award : this.des.replace(/"/g, "'");
      let title = this.form.type === '奖状' ? this.form.name : this.form.title;
      if (this.id === 'add') {
        postAnnounceNew({
          title: title,
          des: des,
          type: this.form.type,
          personnel: this.form.personnel,
          group_id: this.form.groupid,
          is_public: '0',
        }).then(res => {
          console.log(res);
          this.$emit('changeId', res.data.data.toString());
        });
      } else {
        postAnnounceMod({
          title: title,
          des: des,
          type: this.form.type,
          personnel: this.form.personnel,
          group_id: this.form.groupid,
          is_public: '0',
          id: this.id,
        });
      }
      Message({
        type: 'success',
        message: '保存成功',
      });
    },
    saveAndPub() {
      //保存并发布
      let des = this.form.type === '奖状' ? this.form.award : this.des.replace(/"/g, "'");
      let title = this.form.type === '奖状' ? this.form.name : this.form.title;
      if (this.id === 'add') {
        postAnnounceNew({
          title: title,
          des: des,
          type: this.form.type,
          personnel: this.form.personnel,
          group_id: this.form.groupid,
          is_public: '1',
        }).then(res => {
          console.log(res);
          this.form.is_public = '1';
          this.$emit('changeId', res.data.data.toString());
        });
      } else {
        postAnnounceMod({
          title: title,
          des: des,
          type: this.form.type,
          personnel: this.form.personnel,
          group_id: this.form.groupid,
          is_public: '1',
          id: this.id,
        }).then(() => {
          this.form.is_public = '1';
        });
      }
      Message({
        type: 'success',
        message: '发布成功',
      });
    },
    del() {
      postAnnounceDel({
        id: this.id,
      }).then(() => {
        this.$emit('changeId', 'add');
        Message({
          type: 'success',
          message: '公告已从列表删除',
        });
      });
    },
    recall() {
      postAnnounceBack({
        id: this.id,
      }).then(() => {
        this.form.is_public = '0';
        Message({
          type: 'success',
          message: '撤回成功',
        });
      });
    },
  },
};
</script>

<style scoped lang="less">
@import 'addGroup';
</style>
