<template>
  <div class="cover" @click.stop="$emit('close')">
    <div class="groupTemplate" @click="$event.stopPropagation()">
      <div class="title">
        <span>通知模板</span>
        <div class="close-ico" @click.stop="$emit('close')" />
      </div>
      <main>
        <div class="radio" v-for="(item, index) in tem" :key="index">
          <label :for="item.str">
            <img :src="item.img" width="190px" />
            <input type="radio" :id="item.str" :value="item.str" v-model="radio" />
          </label>
        </div>
      </main>
      <footer>
        <button class="ok" @click="ok">确定</button>
        <button class="cancel" @click="$emit('choices')">取消</button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'groupTemplate',
  data() {
    return {
      tem: [
        {
          img: require('../../../assets/image/template_slices/1045@2x.png'),
          str: '群通知',
        },
        {
          img: require('../../../assets/image/template_slices/1046@2x.png'),
          str: '会议通知',
        },
        {
          img: require('../../../assets/image/template_slices/1047@2x.png'),
          str: '放假通知',
        },
        {
          img: require('../../../assets/image/template_slices/1048@2x.png'),
          str: '奖状',
        },
        {
          img: require('../../../assets/image/template_slices/1049@2x.png'),
          str: '紧急通知',
        },
        {
          img: require('../../../assets/image/template_slices/1050@2x.png'),
          str: '工作通知',
        },
      ],
      radio: '群通知',
    };
  },
  methods: {
    ok() {
      this.$emit('choices', this.radio);
    },
  },
};
</script>

<style scoped lang="less">
@import 'groupTemplate';
</style>
